import {
  useMutation,
  useQueryClient,
  type UseMutationOptions,
} from "@tanstack/react-query";
import type { Options } from "@hey-api/client-fetch";

import {
  putV1OrganizationsByOrganizationIdLeadsByLeadIdAssignmentMotivationByLinkId,
  PutV1OrganizationsByOrganizationIdLeadsByLeadIdAssignmentMotivationByLinkIdData,
  PutV1OrganizationsByOrganizationIdLeadsByLeadIdAssignmentMotivationByLinkIdError,
  PutV1OrganizationsByOrganizationIdLeadsByLeadIdAssignmentMotivationByLinkIdResponse,
} from "../../client";
import { jsonParse } from "../utils/parse";
import { putV1OrganizationsByOrganizationIdLeadsByLeadIdAssignmentMotivationByLinkIdMutation } from "../../client/@tanstack/react-query.gen";

const mutationKey = ["updateProfileLinkMotivation"];

export const useUpdateProfileLinkMotivation = (
  options: UseMutationOptions<
    PutV1OrganizationsByOrganizationIdLeadsByLeadIdAssignmentMotivationByLinkIdResponse,
    PutV1OrganizationsByOrganizationIdLeadsByLeadIdAssignmentMotivationByLinkIdError,
    PutV1OrganizationsByOrganizationIdLeadsByLeadIdAssignmentMotivationByLinkIdData
  > = {},
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...putV1OrganizationsByOrganizationIdLeadsByLeadIdAssignmentMotivationByLinkIdMutation(),
    mutationKey,
    ...options,
    onSuccess: (profileLinkConfig, variables, context) => {
      // Call the custom onSuccess handler if provided
      console.log("Motivation addedd succesffully");
      options?.onSuccess?.(profileLinkConfig, variables, context);
    },
    onSettled: (data, error, variables, context) => {
      // Invalidate the query to ensure fresh data

      // Call the custom onSettled handler if provided
      options?.onSettled?.(data, error, variables, context);
    },
  });
};
