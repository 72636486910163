import React, { useState, useEffect } from "react";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { Dayjs } from "dayjs";
import { Input } from "../../../../../components";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";

interface Props {
  placeholder: string;
  onUpdateValue: (date: Dayjs | null) => void;
  value?: Dayjs | null;
  labelText?: string;
}
const DateCalendarInput = ({
  placeholder,
  onUpdateValue,
  value,
  labelText,
}: Props) => {
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const handleCalendarChange = (newValue: Dayjs | null) => {
    setSelectedDate(newValue);
    setShowCalendar(false); // Optionally close the calendar when a date is selected
    if (newValue) {
      onUpdateValue(newValue);
    }
  };

  useEffect(() => {
    if (value) setSelectedDate(value);
  }, [value]);

  const clearValue = () => {
    setSelectedDate(null);
    onUpdateValue(null);
    setShowCalendar(false);
  };

  return (
    <div className="min-w-[320px]">
      <Input
        placeholder={placeholder}
        value={selectedDate ? selectedDate.format("YYYY-MM-DD") : ""}
        onFocus={() => {
          setShowCalendar(!showCalendar);
        }}
        leftIcon={faGlobe}
        rightButton={{ label: "Clear", type: "reset", onClick: clearValue }}
      />
      {showCalendar && (
        <div>
          <DateCalendar
            views={["year", "month", "day"]}
            value={selectedDate}
            onChange={handleCalendarChange}
          />
        </div>
      )}
      <label className="text-gray-6 text-xs mt-6 ml-2">{labelText}</label>
    </div>
  );
};

export default DateCalendarInput;
