import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover, PopoverTrigger } from "../../../../components";
import { LeadButton, type LeadButtonProps } from "./LeadButton";
import { LeadMenu } from "./menu";

interface Props extends LeadButtonProps {
  onArchive: () => void;
  onDelete?: () => void;
  onDeleteContract?: () => void;
  leadId?: string;
  profileLinkId?: string;
  archived?: boolean;
  contractId: string | undefined;
}

export const LeadMenuButton = ({
  onArchive,
  onDelete,
  onDeleteContract,
  leadId,
  profileLinkId,
  archived,
  contractId,
  ...props
}: Props) => (
  <Popover>
    <PopoverTrigger asChild>
      <LeadButton {...props}>
        <FontAwesomeIcon
          width={20}
          height={20}
          icon={faEllipsis}
          className="w-5 h-5 shrink-0"
        />
      </LeadButton>
    </PopoverTrigger>
    <LeadMenu
      onArchive={onArchive}
      profileLinkId={profileLinkId}
      archived={archived}
      onDelete={onDelete}
      leadId={leadId}
      contractId={contractId}
      onDeleteContract={onDeleteContract}
    />
  </Popover>
);
