import { useCallback, useRef } from "react";
import { toast } from "sonner";
import type { Lead } from "../../../../client";
import {
  useDeleteLead,
  useGetClient,
  useUpdateLead,
} from "../../../../services";
import { LeadRow } from "./LeadRow";
import { useDeleteContract } from "../../../../services/organtization/delete-contract";

interface Props {
  organizationId: string;
  lead: Lead;
  index?: number;
  profileId?: string;
}

export const ExistingLeadRow = ({
  organizationId,
  lead,
  index,
  profileId,
}: Props) => {
  if (!lead.id) throw new Error("Missing lead ID");

  const toastId = useRef<string | number>();

  const deleteContract = useDeleteContract(
    {
      onMutate: () => {},
      onSuccess: () => {
        console.log("Contract deleted");
      },
    },
    organizationId,
    profileId,
  );

  const deleteLead = useDeleteLead(organizationId, {
    onMutate: (payload) => {
      console.log("onMutate triggered with payload:", payload);
    },

    onError: () => {
      toast.error("Something went wrong", { id: toastId.current });
    },
  });

  const updateLead = useUpdateLead(
    {
      onMutate: (payload) => {
        if (payload.body.status === "archived") {
          toastId.current = toast.loading("Archiving lead...");
        }
      },
      onSuccess: (_, { body: { status } }) => {
        if (status === "archived")
          toast.success("Lead archived", { id: toastId.current });
      },
      onError: () => {
        toast.error("Something went wrong", { id: toastId.current });
      },
    },
    profileId,
  );

  const { data: client } = useGetClient({
    clientId: updateLead.variables?.body.clientId ?? lead.clientId ?? "",
    organizationID: organizationId,
  });

  const onUpdateLead = useCallback(
    (payload: Partial<Lead>) => {
      updateLead.mutate({
        path: { organizationID: organizationId, leadID: lead.id ?? "" },
        body: { ...lead, ...payload },
      });
    },
    [updateLead, organizationId, lead],
  );

  return (
    <LeadRow
      lead={updateLead.variables?.body ?? lead}
      organizationId={organizationId}
      client={client}
      onUpdateLead={onUpdateLead}
      onDeleteLead={(leadId: string) =>
        deleteLead.mutate({
          path: { organizationID: organizationId, leadID: leadId },
        })
      }
      onDeleteContract={() => {
        if (lead && lead.id && lead.contractId) {
          deleteContract.mutate({
            path: { leadID: lead.id, contractID: lead.contractId },
          });
        }
      }}
      profileId={profileId}
    />
  );
};

ExistingLeadRow.displayName = "ExistingLeadRow";
