import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { getV1OrganizationsByOrganizationIdProfilesByProfileIdLeadsOptions } from "../../../client/@tanstack/react-query.gen";

export const organizationProfileLeadQueryOptions = (
  organizationId: string,
  profileId: string,
) => {
  return queryOptions({
    ...getV1OrganizationsByOrganizationIdProfilesByProfileIdLeadsOptions({
      path: { organizationID: organizationId, profileID: profileId },
    }),
  });
};

export const useGetOrganizationProfileLeads = (
  organizationId: string,
  profileId: string,
) => {
  return useSuspenseQuery(
    organizationProfileLeadQueryOptions(organizationId, profileId),
  );
};
