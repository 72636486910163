import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import {
  Lead,
  PostV1LeadsByLeadIdContractsData,
  PostV1LeadsByLeadIdContractsError,
  PostV1LeadsByLeadIdContractsResponse,
} from "../../client";
import { postV1LeadsByLeadIdContractsMutation } from "../../client/@tanstack/react-query.gen";
import { Options } from "@hey-api/client-fetch";
import { getLeadsQueryKey } from "./lead/utils";
import { jsonParse } from "../utils/parse";
import { organizationProfileLeadQueryOptions } from "./lead/get-profile-leads";

const mutationKey = ["createContract"];

export const useCreateContract = (
  options: UseMutationOptions<
    PostV1LeadsByLeadIdContractsResponse,
    PostV1LeadsByLeadIdContractsError,
    Options<PostV1LeadsByLeadIdContractsData>
  > = {},
  organizationId: string,
  profileId?: string | null,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...postV1LeadsByLeadIdContractsMutation(),
    mutationKey,
    ...options,
    onSuccess: (lead, variables, context) => {
      const newLead = jsonParse<Lead>(lead);

      queryClient.setQueryData(getLeadsQueryKey(newLead.orgId!), (oldData) => {
        if (!oldData) return [newLead];
        const index = oldData.findIndex((item) => item.id === newLead.id);
        if (index === -1) return [...oldData, newLead];
        return [
          ...oldData.slice(0, index),
          newLead,
          ...oldData.slice(index + 1),
        ];
      });

      if (profileId) {
        const profileQueryKey = organizationProfileLeadQueryOptions(
          organizationId,
          profileId,
        ).queryKey;

        queryClient.setQueryData(profileQueryKey, (oldData) => {
          if (!oldData) return [newLead];
          const index = oldData.findIndex((item) => item.id === newLead.id);
          if (index === -1) return [...oldData, newLead];
          return [
            ...oldData.slice(0, index),
            newLead,
            ...oldData.slice(index + 1),
          ];
        });
      }

      options.onSuccess?.(lead, variables, context);
    },
    onError: (error, variables, context) => {
      // Add any custom error handling here
      console.log(error);
      // Call the original onError if provided
      options.onError?.(error, variables, context);
    },
  });
};
