import { useCallback, useEffect } from "react";
import type { Lead, OrganizationOverview } from "../../../../client";
import { useGetClient, useGetLead, useUpdateLead } from "../../../../services";
import { type LeadRowActiveState, homeState } from "../../../../state/home";
import { LeadAssignmentButton } from "./LeadAssignmentButton";
import { LeadClientButton } from "./LeadClientButton";
import { LeadEventsButton } from "./LeadEventsButton";
import { LeadMemberButton } from "./LeadMemberButton";
import { LeadMenuButton } from "./LeadMenuButton";
import { LeadQRButton } from "./LeadQRButton";
import { LeadTemperatureButton } from "./LeadTemperatureButton";

interface Props {
  organizationId: string;
  leadId?: string;
  state: LeadRowActiveState;
  organizastionOverview: OrganizationOverview;
}

export const LeadDrawerMenu = ({
  organizationId,
  leadId,
  state,
  organizastionOverview,
}: Props) => {
  const updateLead = useUpdateLead();

  const { data: lead } = useGetLead(
    { leadID: leadId ?? "", organizationID: organizationId },
    { enabled: !!leadId },
  );

  useEffect(() => {
    console.log("Lead state:", {
      lead,
      leadId,
      isEnabled: !!leadId,
      hasData: !!lead,
    });
  }, [lead, leadId]);

  const { data: client } = useGetClient({
    clientId: updateLead.variables?.body.clientId ?? lead?.clientId ?? "",
    organizationID: organizationId,
  });

  const onUpdateLead = useCallback(
    (payload: Partial<Lead>) => {
      if (!lead) return;
      updateLead.mutate({
        path: { organizationID: organizationId, leadID: lead.id ?? "" },
        body: { ...lead, ...payload },
      });
    },
    [updateLead, organizationId, lead],
  );

  const onButtonClick = (state: LeadRowActiveState) => {
    homeState.selectedLead = {
      leadId: leadId ?? null,
      state,
    };
  };

  return (
    <div id="lead-drawer-menu" className="flex flex-col gap-2 -mx-4 px-4">
      <div className="flex flex-row w-full">
        <div className="flex-1 basis-1/2">
          <LeadAssignmentButton
            active={state === "assignment"}
            onClick={() => {
              onButtonClick("assignment");
            }}
            title={lead?.title || lead?.assignmentInfo?.title}
            logoId={lead?.companyLogoId}
            companyName={lead?.companyName}
            disabled={lead?.status === "archived"}
          />
        </div>
        <div className="flex-1 basis-1/2">
          <LeadMemberButton
            active={state === "member"}
            onClick={() => {
              onButtonClick("member");
            }}
            profileId={lead?.profileId ?? undefined}
            organizationId={organizationId}
            profiles={organizastionOverview.profiles}
            numberOfMatchingMembers={null}
            className="px-4 flex-1"
            id="lead-drawer-menu-member"
            disabled={lead?.status === "archived"}
          />
        </div>
      </div>
      <div className="flex flex-row justify-between">
        <LeadEventsButton
          active={state === "events"}
          onClick={() => {
            onButtonClick("events");

            if (lead && (lead.numberOfUnreadEvents ?? 0) > 0)
              onUpdateLead?.({ numberOfUnreadEvents: 0 });
          }}
          numberOfUnreadEvents={lead?.numberOfUnreadEvents ?? 0}
          className="px-4"
          id="lead-drawer-menu-events"
          disabled={lead?.status === "archived"}
        />
        <LeadTemperatureButton
          priority={lead?.priority ?? 0}
          onPriorityChange={(priority) => onUpdateLead?.({ priority })}
          className="px-4"
          id="lead-drawer-menu-temperature"
          disabled={lead?.status === "archived"}
        />
        <LeadMenuButton
          className="px-4"
          onArchive={() => onUpdateLead?.({ status: "archived" })}
          id="lead-drawer-menu-menu"
          contractId={lead?.contractId}
        />
      </div>
    </div>
  );
};
