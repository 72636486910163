import {
  useMutation,
  useQueryClient,
  type UseMutationOptions,
} from "@tanstack/react-query";
import type { Options } from "@hey-api/client-fetch";

import {
  DeleteV1LeadsByLeadIdContractsByContractIdData,
  DeleteV1LeadsByLeadIdContractsByContractIdResponse,
  DeleteV1LeadsByLeadIdContractsByContractIdError,
  deleteV1LeadsByLeadIdContractsByContractId,
  Lead,
} from "../../client";
import { jsonParse } from "../utils/parse";
import { organizationProfileLeadQueryOptions } from "./lead/get-profile-leads";
import { getLeadsQueryKey } from "./lead/utils";

const mutationKey = ["deleteContract"];

export const useDeleteContract = (
  options: UseMutationOptions<
    DeleteV1LeadsByLeadIdContractsByContractIdResponse,
    DeleteV1LeadsByLeadIdContractsByContractIdError,
    DeleteV1LeadsByLeadIdContractsByContractIdData
  > = {},
  organizationId: string,
  profileId?: string,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey,
    mutationFn: async (
      data: DeleteV1LeadsByLeadIdContractsByContractIdData,
    ) => {
      const { data: responseData } =
        await deleteV1LeadsByLeadIdContractsByContractId({
          ...data,
          throwOnError: true,
        });
      return responseData;
    },
    ...options,
    onSuccess: (lead, variables, context) => {
      const newLead = jsonParse<Lead>(lead);

      queryClient.setQueryData(getLeadsQueryKey(newLead.orgId!), (oldData) => {
        if (!oldData) return [newLead];
        const index = oldData.findIndex((item) => item.id === newLead.id);
        if (index === -1) return [...oldData, newLead];
        return [
          ...oldData.slice(0, index),
          newLead,
          ...oldData.slice(index + 1),
        ];
      });

      if (profileId) {
        const profileQueryKey = organizationProfileLeadQueryOptions(
          organizationId,
          profileId,
        ).queryKey;

        queryClient.setQueryData(profileQueryKey, (oldData) => {
          if (!oldData) return [newLead];
          const index = oldData.findIndex((item) => item.id === newLead.id);
          if (index === -1) return [...oldData, newLead];
          return [
            ...oldData.slice(0, index),
            newLead,
            ...oldData.slice(index + 1),
          ];
        });
      }

      // Call the custom onSuccess handler if provided
      options?.onSuccess?.(lead, variables, context);
    },
    onSettled: (data, error, variables, context) => {
      // Invalidate the query to ensure fresh data

      // Call the custom onSettled handler if provided
      options?.onSettled?.(data, error, variables, context);
    },
  });
};
