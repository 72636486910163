import { useSuspenseQuery } from "@tanstack/react-query";
import type { GetV1OrganizationsByOrganizationIdResponse } from "../../client";
import { jsonParse } from "../utils/parse";
import type { QueryConfig } from "../utils/query-config";
import { getOrganizationOptions } from "./get-organization";

type Input = {
  organizationId: string;
  profileId: string;
};

export const useGetProfile = (
  { organizationId, profileId }: Input,
  config?: QueryConfig,
) => {
  return useSuspenseQuery({
    ...getOrganizationOptions(organizationId),
    ...config,
    select: (data) => {
      if (data)
        // Currently, getV1OrganizationsByOrganizationIdOptions returns as string
        // so we need to parse it
        return jsonParse<GetV1OrganizationsByOrganizationIdResponse>(
          data,
        ).profiles.find((profile) => profile.profileId === profileId);
      return data;
    },
  });
};
