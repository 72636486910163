import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import {
  type PropsWithChildren,
  Suspense,
  useEffect,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { useSnapshot } from "valtio";
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerTitle,
} from "../../../../../components/drawer";
import { LoadingSpinner } from "../../../../../components/loading";

import { homeState } from "../../../../../state/home";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs, { Dayjs } from "dayjs";

import DateCalendarInput from "./DateCalendarInput";
import { useGetLead, useGetProfile } from "../../../../../services";
import { ContractHeaderHeader } from "./ContractDrawerHeader";
import { useCreateContract } from "../../../../../services/organtization/create-contract";
import { toast } from "sonner";
import { LeadRowCard } from "../card/LeadRowCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-regular-svg-icons";
import { UserProfile } from "../../../../../client";
import { useUpdateContract } from "../../../../../services/organtization/contract/update-contract";

export const ContractDrawer = ({ children }: PropsWithChildren) => {
  const { organizationId, memberId } = useParams();
  if (!organizationId) throw new Error("Organization ID is required");

  const toastId = useRef<string | number>();

  const [selectedStartDate, setSelectedStartDate] = useState<Dayjs | null>(
    null,
  );
  const [currentProfileName, setCurrentProfileName] = useState<string>("");

  const [selectedEndDate, setSelectedEndDate] = useState<Dayjs | null>(null);

  const { contractCreatorLeadId } = useSnapshot(homeState);

  const { data: lead } = useGetLead(
    {
      leadID: contractCreatorLeadId ?? "",
      organizationID: organizationId,
    },
    { enabled: !!contractCreatorLeadId },
  );

  const { data: profile } = useGetProfile(
    {
      organizationId,
      profileId: lead?.profileId ?? "",
    },
    {
      enabled: !!lead,
    },
  );

  useEffect(() => {
    if (profile) {
      const userProfile = profile as UserProfile | undefined;
      const name: string = userProfile?.profileData.name as string;
      setCurrentProfileName(name);
    }
  }, [profile]);

  useEffect(() => {
    if (lead && lead.contract) {
      let startDate = dayjs(lead.contract.startDate);
      setSelectedStartDate(startDate);
      if (lead.contract.endDate) {
        setSelectedEndDate(dayjs(lead.contract.endDate));
      }
    }
  }, [lead]);

  const createContract = useCreateContract(
    {
      onSuccess: () => {
        toastId.current = toast.success(
          `Contract created and moved to ${currentProfileName}´s page`,
        );
      },
      onError: (error) => {
        if (error instanceof Error) {
          toast.error("Something went wrong", { id: toastId.current });
        }
        console.error(error);
      },
    },
    organizationId,
    lead ? lead.profileId : memberId ? memberId : null,
  );

  const updateContract = useUpdateContract(
    {
      onSuccess: () => {
        toastId.current = toast.success(
          `Contract created and moved to ${currentProfileName}´s page`,
        );
      },
      onError: (error) => {
        if (error instanceof Error) {
          toast.error("Something went wrong", { id: toastId.current });
        }
        console.error(error);
      },
    },
    organizationId,
    lead ? lead.profileId : memberId ? memberId : null,
  );

  const handleCreate = () => {
    if (lead?.contractId) {
      if (selectedStartDate) {
        try {
          updateContract.mutate({
            path: {
              leadID: contractCreatorLeadId!,
              contractID: lead?.contractId,
            },
            body: {
              id: "",
              startDate: selectedStartDate.format("YYYY-MM-DDTHH:mm:ssZ"),
              endDate: selectedEndDate
                ? selectedEndDate.format("YYYY-MM-DDTHH:mm:ssZ")
                : null,
            },
          });
          setSelectedStartDate(null);
          setSelectedEndDate(null);
          homeState.contractCreatorLeadId = null;
        } catch (error) {}
      }
      return;
    }
    if (selectedStartDate) {
      try {
        createContract.mutate({
          path: { leadID: contractCreatorLeadId! },
          body: {
            id: "",
            startDate: selectedStartDate.format("YYYY-MM-DDTHH:mm:ssZ"),
            endDate: selectedEndDate
              ? selectedEndDate.format("YYYY-MM-DDTHH:mm:ssZ")
              : null,
          },
        });
        setSelectedStartDate(null);
        setSelectedEndDate(null);
        homeState.contractCreatorLeadId = null;
      } catch (error) {}
    }
  };

  const handleProfileEditorClose = () => {
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    setCurrentProfileName("");
    homeState.contractCreatorLeadId = null;
  };

  const noProfileNotice = () => {
    return (
      <div className="flex flex-1 w-full justify-end p-10">
        <div className="flex flex-1 max-w-[700px] flex-col">
          <LeadRowCard
            className="text-xs bg-red-2 border-red-3 mt-3"
            type="dashed"
          >
            <div className="flex flex-1 items-center gap-3">
              <FontAwesomeIcon
                width={14}
                height={14}
                icon={faBuilding}
                className="w-4 h-4 shrink-0"
              />
              <p>
                You need to add a member to the Lead before you can create a
                Contract
              </p>
            </div>
          </LeadRowCard>
        </div>
      </div>
    );
  };

  const noCompanyNotice = () => {
    return (
      <div className="flex flex-1 w-full justify-end p-10">
        <div className="flex flex-1 max-w-[700px] flex-col">
          <LeadRowCard
            className="text-xs bg-red-2 border-red-3 mt-3"
            type="dashed"
          >
            <div className="flex flex-1 items-center gap-3">
              <FontAwesomeIcon
                width={14}
                height={14}
                icon={faBuilding}
                className="w-4 h-4 shrink-0"
              />
              <p>
                You need to add a company to the Lead before you can create a
                Contract
              </p>
            </div>
          </LeadRowCard>
        </div>
      </div>
    );
  };

  return (
    <Drawer
      direction="right"
      open={!!contractCreatorLeadId}
      onClose={handleProfileEditorClose}
      shouldScaleBackground={false}
    >
      {children}

      <DrawerContent
        className="min-w-[60%] max-w-[60%] flex flex-col"
        direction="right"
      >
        <VisuallyHidden.Root>
          <DrawerTitle>Edit link</DrawerTitle>
          <DrawerDescription>
            Edit the link for the profile version
          </DrawerDescription>
        </VisuallyHidden.Root>

        <ContractHeaderHeader
          handleClose={handleProfileEditorClose}
          handleCreateClick={handleCreate}
          contractInputIValid={selectedStartDate != null}
          contractExists={!!lead?.contractId}
        />
        <div className="flex flex-1 justify-center">
          {!lead?.profileId ||
          (lead?.companyUrl === "" && lead.companyName === "") ? (
            !lead?.profileId ? (
              noProfileNotice()
            ) : (
              lead?.companyUrl === "" &&
              lead.companyName === "" &&
              noCompanyNotice()
            )
          ) : (
            <div className="flex flex-1 w-full justify-center p-10">
              <div className="flex flex-1 flex-col">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div>
                    <label className="text-gray-6 text-sm mt-6">
                      Contract duration
                    </label>
                  </div>
                  <label className="text-gray-6 text-sm mt-4"></label>
                  <div className="flex flex-row justify-between gap-3">
                    <DateCalendarInput
                      placeholder="Start date"
                      value={selectedStartDate}
                      onUpdateValue={(newDate: Dayjs | null) =>
                        setSelectedStartDate(newDate)
                      }
                    />
                    <DateCalendarInput
                      placeholder="End date"
                      value={selectedEndDate}
                      onUpdateValue={(newDate: Dayjs | null) =>
                        setSelectedEndDate(newDate)
                      }
                      labelText="Leave empty if you do not have a last date"
                    />
                  </div>
                </LocalizationProvider>
              </div>
            </div>
          )}
        </div>
      </DrawerContent>
    </Drawer>
  );
};

const Loading = () => (
  <div className="flex justify-center items-center h-full w-full">
    <LoadingSpinner size="small" />
  </div>
);
