import {
  Suspense,
  useCallback,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { toast } from "sonner";
import isURL from "validator/lib/isURL";
import { useSnapshot } from "valtio";
import type {
  AssignmentInfo,
  Client,
  ClientWithContact,
  Lead,
} from "../../../../client";
import { LoadingSpinner } from "../../../../components";
import {
  useCreateLead,
  useGetClient,
  useGetClients,
  useGetLead,
  useUpdateClient,
  useUpdateLead,
} from "../../../../services";
import { useRequestAssignmentInfo } from "../../../../services/organtization/request-assigment-info";
import { jsonParse } from "../../../../services/utils/parse";
import { type LeadRowActiveState, homeState } from "../../../../state/home";
import { useToggleActiveState } from "../utils/use-toggle-active-state";
import {
  AnimatedPanel,
  AssignmentPanel,
  EventsPanel,
  MemberPanel,
  QRPanel,
} from "./panel";
import { useRequestClientInfo } from "../../../../services/organtization/client/request-client-info";

interface Props {
  organizationId: string;
  leadId?: string;
  state: LeadRowActiveState;
  divider?: boolean;
  className?: string;
  onMatchingMembers: (numberOfMatchingMembers: number) => void;
  onLeadCreated: () => void;
  profileId?: string;
}

const blankLead: Lead = {
  id: "",
  clientId: "",
  title: "",
  description: "",
  contactPersonName: "",
  contactPersonEmail: "",
  contactPersonPhone: "",
  contractId: "",
  status: "",
  numberOfUnreadEvents: 0,
  // Will always get overridden before being sent to the backend
  orgId: "",
  profileVersions: [],
};

const clientWithContactToClient = (client: ClientWithContact): Client => ({
  ...client,
  description: client.description ?? "",
  url: client.url ?? "",
});

export const LeadPanelContent = ({
  organizationId,
  leadId,
  state,
  divider,
  className,
  onMatchingMembers,
  onLeadCreated,
  profileId,
}: Props) => {
  const { selectedLead } = useSnapshot(homeState);

  const updateLead = useUpdateLead({}, profileId);
  const updateClient = useUpdateClient();
  const { scrollToLead } = useToggleActiveState({ leadId: leadId ?? null });

  const [editingAssignmentLink, setEditingAssignmentLink] = useState(false);
  const [editingWebsiteLink, setEditingWebsiteLink] = useState(false);
  const [couldNotLoadFromUrl, setCouldNotLoadFromUrl] = useState(false);
  const [couldNotLoadClientFromUrl, setCouldNotClientLoadFromUrl] =
    useState(false);

  const createLead = useCreateLead({
    onSuccess: (lead) => {
      const newLead = jsonParse<Lead>(lead);

      if (selectedLead) {
        // Navigate to the new lead
        homeState.selectedLead = {
          leadId: newLead.id ?? "",
          state,
        };
        onLeadCreated();
        scrollToLead(newLead.id ?? "");
      }
    },
  });

  const { data: lead } = useGetLead(
    { organizationID: organizationId, leadID: leadId ?? "" },
    {
      enabled: !!leadId,
    },
  );

  const { data: clients } = useGetClients(organizationId);

  const { data: client } = useGetClient({
    clientId: updateLead.variables?.body.clientId ?? lead?.clientId ?? "",
    organizationID: organizationId,
  });

  const onMutateLead = useCallback(
    (payload: Partial<Lead>) => {
      if (lead) {
        // Update existing lead if it exists
        updateLead.mutate({
          path: { organizationID: organizationId, leadID: lead.id ?? "" },
          body: { ...lead, ...payload },
        });
      } else {
        // Create new lead if it doesn't exist

        createLead.mutate({
          body: {
            ...blankLead,
            orgId: organizationId,
            ...payload,
          },
          path: {
            organizationID: organizationId,
          },
        });
      }
    },
    [lead, updateLead, organizationId, createLead],
  );

  const onMutateClient = useCallback(
    (payload: Partial<Client>) => {
      if (!client) return;

      updateClient.mutate({
        path: { organizationID: organizationId, clientID: client.id },
        body: { ...clientWithContactToClient(client), ...payload },
      });
    },
    [updateClient, organizationId, client],
  );

  const requestInfoToastId = useRef<string | number>();

  const requestWebsiteClientInfo = useRequestClientInfo({
    onMutate: () => {
      requestInfoToastId.current = toast.loading(
        "Getting info about assignment...",
      );
    },
    onSuccess: (clientInfoResponse, { query: { url } }) => {
      toast.success("Updated company info", {
        id: requestInfoToastId.current,
      });

      setEditingWebsiteLink(false);

      if (clientInfoResponse.failedToLoadData) {
        setCouldNotClientLoadFromUrl(true);
      } else {
        onMutateLead({
          companyName: clientInfoResponse.client?.name,
          companyUrl: url,
          companyDesc: clientInfoResponse.client?.description,
          logoSuggestions: clientInfoResponse.client?.logoSuggestions,
        });
      }
    },
    onError: () => {
      toast.error("Something went wrong", { id: requestInfoToastId.current });
    },
  });

  const requestAssignmentInfo = useRequestAssignmentInfo({
    onMutate: () => {
      requestInfoToastId.current = toast.loading(
        "Getting info about assignment...",
      );
    },
    onSuccess: (assignmentInfo, { body: { url, info } }) => {
      try {
        const parsedInfo = jsonParse<AssignmentInfo>(assignmentInfo);
        setCouldNotLoadFromUrl(false);

        if (parsedInfo.couldNotLoadFromUrl) {
          toast.success("Assignment info fetch failed", {
            id: requestInfoToastId.current,
          });
          setCouldNotLoadFromUrl(true);
        } else {
          setEditingAssignmentLink(false);
          toast.success("Updated assignment info", {
            id: requestInfoToastId.current,
          });

          onMutateLead({
            assignmentInfo: {
              ...parsedInfo,
              assignmentUrl: url,
            },
          });
        }
      } catch (error) {
        toast.error("Failed to parse assignment information", {
          id: requestInfoToastId.current,
        });
        // Optionally set editing link back to false here if needed
        setEditingAssignmentLink(false);
      }
    },
    onError: () => {
      toast.error("Something went wrong", { id: requestInfoToastId.current });
    },
  });

  const leadProfileId =
    updateLead.variables?.body.profileId ?? lead?.profileId ?? undefined;
  const child = useMemo(() => {
    switch (state) {
      case "assignment":
        return (
          <AssignmentPanel
            disabled={requestAssignmentInfo.isPending}
            onRequestAssignmentInfo={async (url) => {
              if (isURL(url) && !requestAssignmentInfo.isPending) {
                try {
                  await requestAssignmentInfo.mutateAsync({
                    body: { url, info: "" },
                  });
                } catch (error) {
                  console.error("Assignment info request failed:", error);
                  // Handle error appropriately
                }
              }
            }}
            onRequestAssignmentInfoWithInfo={(info) => {
              requestAssignmentInfo.mutate({ body: { info, url: "" } });
            }}
            onRequestWebsiteInfo={(url) => {
              if (isURL(url) && !requestWebsiteClientInfo.isPending) {
                requestWebsiteClientInfo.mutate({ query: { url } });
              }
            }}
            onSelectLogo={(logoUrl) => {
              if (logoUrl === "") {
                onMutateLead({
                  companyLogoId: undefined,
                  logoSuggestionOrigin: undefined,
                });
              } else {
                onMutateLead({
                  companyLogoId: logoUrl,
                });
              }
            }}
            lead={lead}
            onMutateLead={onMutateLead}
            isEditingWebsiteLink={editingWebsiteLink}
            isEditingAssignmentLink={editingAssignmentLink}
            onEditWebsiteLinkClick={() => setEditingWebsiteLink(true)}
            onEditAssigmentLinkClick={() => setEditingAssignmentLink(true)}
            couldNotLoadFromUrl={couldNotLoadFromUrl}
            organizationId={organizationId}
            onMatchingMembers={onMatchingMembers}
            couldNotLoadClientFromUrl={couldNotLoadClientFromUrl}
          />
        );
      case "member":
        return (
          <MemberPanel
            profileId={leadProfileId}
            profileVersions={lead?.profileVersions ?? []}
            onMutateLead={onMutateLead}
            profileLinkId={lead?.profileLinkId}
            leadId={lead?.id}
            assignmentInfo={lead?.assignmentInfo}
            profileCanBeReplaced={!lead?.contractId}
          />
        );
      case "qr":
        return (
          <QRPanel profileLinkId={lead?.profileLinkId} leadId={lead?.id} />
        );
      case "events":
        return (
          <EventsPanel
            organizationId={organizationId}
            leadId={lead?.id}
            events={lead?.events ?? []}
            onResetNumberOfUnreadEvents={() =>
              onMutateLead({ numberOfUnreadEvents: 0 })
            }
          />
        );
      default:
        return null;
    }
  }, [
    state,
    client,
    clients,
    lead,
    onMutateLead,
    onMutateClient,
    requestAssignmentInfo,
    leadProfileId,
    organizationId,
  ]);

  return (
    <AnimatedPanel state={state} className={className}>
      {divider && <div className="w-full h-px mt-4 bg-gray-2" />}
      <div className="py-8 lg:px-4">
        <Suspense fallback={<Loading />}>{child}</Suspense>
      </div>
    </AnimatedPanel>
  );
};

const Loading = () => (
  <div className="flex justify-center items-center h-full w-full">
    <LoadingSpinner size="small" />
  </div>
);
