import { faBell, faBuilding } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LeadButton, type LeadButtonProps } from "./LeadButton";
import { NumberOfUnreadEvents } from "./NumberOfUnreadEvents";
import dayjs from "dayjs";

interface Props extends LeadButtonProps {
  validUntilDate: string | undefined | null;
}

export const LeadContractButton = ({ validUntilDate, ...props }: Props) => (
  <LeadButton {...props}>
    <div className="flex items-center gap-1">
      <FontAwesomeIcon
        width={20}
        height={20}
        icon={faBuilding}
        className="w-5 h-5"
      />

      <div className="flex flex-col">
        <p>Contract</p>
        <p className="text-red-3">
          {validUntilDate
            ? `Until ${dayjs(validUntilDate).format("YYYY-MM-DD")}`
            : "No end date set"}
        </p>
      </div>
    </div>
  </LeadButton>
);
