import { TooltipProvider } from "@radix-ui/react-tooltip";
import type { SkillTag, UserProfile } from "../../../../../client";
import {
  Image,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../../../components";
import { cn } from "../../../../../components/utils/cn";
import { getImageUrl } from "../../../../../utils/image";
import { iconForName } from "../../../../../components/TechIcons";

interface Props extends UserProfile {
  selected: boolean;
  onClick: () => void;
  disabled?: boolean;
  matchingSkills?: SkillTag[];
}

export const MemberItem = ({
  profileData,
  profilePhotoId,
  selected,
  onClick,
  disabled,
  matchingSkills,
}: Props) => (
  <button
    type="button"
    className={cn(
      "flex flex-row gap-2 items-center max-w-fit p-4 hover:bg-gray-2 rounded-2xl border border-transparent transition-all active:scale-95 disabled:opacity-15",
      selected && "bg-gray-3 border-gray-4",
    )}
    onClick={onClick}
    disabled={disabled}
  >
    <Image
      src={profilePhotoId ? getImageUrl(profilePhotoId) : undefined}
      alt={typeof profileData.name === "string" ? profileData.name : "Profile"}
      className="rounded-xl w-10 h-10 flex-shrink-0"
    />
    <div>
      <p className="text-sm truncate">
        {typeof profileData.name === "string" ? profileData.name : "Unknown"}
      </p>
      {matchingSkills && (
        <div className="flex gap-1">
          {matchingSkills.map((skill, index) => (
            <TooltipProvider key={`${skill.name}-${index}`} delayDuration={0}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div className="w-6 h-6">
                    {iconForName(skill.customIconName || skill.name)}
                  </div>
                </TooltipTrigger>
                <TooltipContent className="mb-2 z-10 bg-white">
                  <p>{skill.name}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ))}
        </div>
      )}
    </div>
  </button>
);

MemberItem.displayName = "MemberItem";
