import type React from "react";
import { useEffect, useState } from "react";
import CardTab from "./CardTab";
import {
  AtSign,
  Bell,
  Briefcase,
  FileText,
  Globe,
  Info,
  MessageCircle,
  User,
} from "react-feather";
import IconInput from "./IconInput";
import {
  type AssignmentInfo,
  type Client,
  ClientPreviewData,
  type Lead,
  type ResourceComment,
  type ResourceEvent,
  type UserProfile,
} from "../types";

import {
  createComment,
  createContract,
  createLead,
  resetNotificationCount,
  updateLead,
} from "../requests";
import Button, { ButtonStyleType } from "./atoms/Button";
import ClientEditorTab from "./ClientEditorTab";
import AvatarImage from "./AvatarImage";
import ProfileCard from "./ProfileCard";
import QRCode from "react-qr-code";
import { useCurrentOrgId } from "../hooks/hooks";
import SkillIcon from "./atoms/SkillIcon";
import ExpandableList from "./ExpandableList";
import AssignmentEditor from "./leads/AssignmentEditor";
import { clear } from "console";
import RowMenu, { RowMenuAction } from "./RowMenu";
import PriorityMenu from "./PriorityMenu";
import IconTextArea from "./IconTextArea";
import ResourceCommentRow from "./ResourceCommentRow";
import ResourceEventRow from "./ResourceEventRow";

export enum LeadEditorTabType {
  None = 0,
  Client = 1,
  Assignment = 2,
  Profile = 3,
  Version = 4,
  Events = 5,
}

interface LeadEditorProps {
  lead?: Lead;
  client?: Client;
  onSelect: (leadId: string) => void;
  onClose: () => void;
  selected: boolean;
  orgUserProfiles: UserProfile[];
  isNew?: boolean;
  onEditProfileLinkClick: (tabId: string) => void;
  lastLeadTabId?: string;
  clearLastLeadTab?: () => void;
  onViewProfileLinkClick: (tabId: string, profileLink: string) => void;
  onNewLeadCreated?: (lead: Lead, client?: Client | null) => void;
  onLeadArchived?: (lead: Lead) => void;
  orgClients?: Client[];
  onShowCreateContractDialog?: (leadId: string) => void;
}

const LeadEditor: React.FC<LeadEditorProps> = ({
  lead,
  client,
  onSelect,
  onClose,
  selected,
  orgUserProfiles,
  lastLeadTabId,
  onEditProfileLinkClick,
  isNew,
  clearLastLeadTab,
  onViewProfileLinkClick,
  onNewLeadCreated,
  onLeadArchived,
  orgClients,
  onShowCreateContractDialog,
}) => {
  const [selectedTab, setSelectedTab] = useState<string | null>(null);
  const [currentLead, setCurrentLead] = useState<Lead | null>(null);
  const [currentClient, setCurrentClient] = useState<Client | null>(null);
  const [selectedTabType, setSelectedTabType] = useState<LeadEditorTabType>(
    LeadEditorTabType.None,
  );
  const [selectedProfileVisitId, setSelectedProfileVisitId] =
    useState<string>("");

  useState<AssignmentInfo | null>(null);

  const currentOrgId = useCurrentOrgId();

  useEffect(() => {
    if (lead) {
      setCurrentLead({ ...lead });
    } else {
      setCurrentLead({
        id: "",
        title: "",
        clientId: "",
        description: "",
        contactPersonName: "",
        contactPersonEmail: "",
        contactPersonPhone: "",
        profileLinkId: "",
        status: "",
        comments: [],
        events: [],
        numberOfUnreadEvents: 0,
      });
    }
  }, [lead]);

  useEffect(() => {
    if (client) {
      setCurrentClient({ ...client });
    } else {
      const newClient: Client = {
        id: "",
        name: "",
        organizationId: localStorage.getItem("current-org-id")!,
        url: "",
        description: "",
      };
      setCurrentClient(newClient);
    }
  }, [client]);

  useEffect(() => {
    if (selected === true && selectedTabType === LeadEditorTabType.None) {
      if (currentClient?.id !== "") {
        setSelectedTabType(LeadEditorTabType.Client);
      } else if (currentLead?.title !== "") {
        setSelectedTabType(LeadEditorTabType.Assignment);
      } else if (currentLead?.profileId !== "") {
        setSelectedTabType(LeadEditorTabType.Version);
      }
    }
  }, [selected]);

  useEffect(() => {
    if (lastLeadTabId && lastLeadTabId !== "") {
      setSelectedTab(lastLeadTabId);
      clearLastLeadTab!();
    }
  }, [lastLeadTabId]);

  const clientExist = () => {
    return currentClient?.id !== "";
  };

  const getUserProfile = (profileId: string) => {
    return orgUserProfiles.filter((up) => up.profileId === profileId)[0];
  };

  const handleTabClick = (type: LeadEditorTabType) => {
    if (tabTypeIsSelected(type)) {
      handleOnClose();
      return;
    }
    if (currentLead) {
      onSelect(currentLead.id);
    }
    setSelectedTabType(type);
  };

  const handleOnClose = () => {
    setSelectedTabType(LeadEditorTabType.None);
    onClose();
  };

  const saveLead = (updatedLead: Lead) => {
    if (currentLead) {
      updateLead(currentOrgId!, updatedLead.id, updatedLead)
        .then((l: Lead) => {
          if (onLeadArchived && l.status === "archived") {
            onLeadArchived(l);
            return;
          }
          setCurrentLead(l);
        })
        .catch((err) => {
          console.error("Lead creation process failed:", err);
        });
    }
  };
  const tabTypeIsSelected = (type: LeadEditorTabType) => {
    return selectedTabType === type;
  };

  const handleInputLeadDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    if (currentLead) {
      setCurrentLead({
        ...currentLead,
        description: event.target.value,
      });
    }
  };

  const clearData = () => {
    setCurrentLead({
      id: "",
      title: "",
      clientId: "",
      description: "",
      contactPersonName: "",
      contactPersonEmail: "",
      contactPersonPhone: "",
      profileLinkId: "",
      status: "",
      comments: [],
      events: [],
      numberOfUnreadEvents: 0,
    });
    setCurrentClient({
      id: "",
      name: "",
      organizationId: localStorage.getItem("current-org-id")!,
      url: "",
      description: "",
    });
    setSelectedTabType(LeadEditorTabType.None);
  };

  const handleInputLeadTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (currentLead) {
      setCurrentLead({
        ...currentLead,
        title: event.target.value,
      });
    }
  };

  function isResourceComment(object: any): object is ResourceComment {
    return "comment" in object;
  }

  // Type guard for ResourceEvent
  function isResourceEvent(object: any): object is ResourceEvent {
    return "eventType" in object;
  }

  const renderContent = () => {
    switch (selectedTabType) {
      case LeadEditorTabType.Client:
        return (
          <ClientEditorTab
            client={currentClient!}
            lead={currentLead!}
            onClientSave={(client: Client) => {
              //Set client to update the tab content
              setCurrentClient(client);
            }}
            onLeadCreated={(newLead: Lead, newClient: Client) => {
              if (onNewLeadCreated && isNew) {
                onNewLeadCreated(newLead, newClient);
                clearData();
              }
              setCurrentLead(newLead);
            }}
            onLeadSave={(updatedLead: Lead) => {
              setCurrentLead(updatedLead);
            }}
            orgClients={orgClients}
          />
        );
      case LeadEditorTabType.Assignment:
        return (
          <AssignmentEditor
            lead={currentLead}
            onSaveLead={(savedLead: Lead) => {
              setCurrentLead(savedLead);
            }}
            onLeadCreated={(newLead: Lead) => {
              if (onNewLeadCreated && isNew) {
                onNewLeadCreated(newLead, currentClient ?? null);
                clearData();
              }
              setCurrentLead(newLead);
            }}
          />
        );
      case LeadEditorTabType.Profile:
        return (
          <div className="container mx-auto p-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              {orgUserProfiles.map((p, index) => {
                return (
                  <ProfileCard
                    key={index}
                    userProfile={p}
                    selected={false}
                    onClick={() => {
                      const updatedLead = { ...currentLead! };
                      updatedLead.profileId = p.profileId;

                      if (currentLead?.id === "") {
                        createLead(currentOrgId!, updatedLead).then(
                          (createdLead: Lead) => {
                            setCurrentLead(createdLead);
                            if (onNewLeadCreated) {
                              onNewLeadCreated(createdLead, null);
                              clearData();
                            }
                          },
                        );
                      } else {
                        updateLead(
                          currentOrgId!,
                          currentLead!.id,
                          updatedLead,
                        ).then((lead: Lead) => {
                          setCurrentLead(lead);
                          setSelectedTabType(LeadEditorTabType.Version);
                        });
                      }
                    }}
                  />
                );
              })}
            </div>
            <div className="flex flex-1 justify-end mt-4">
              <Button
                style={ButtonStyleType.primary}
                text="Continue"
                onButtonClick={() => {
                  setSelectedTab("Version");
                }}
              />
            </div>
          </div>
        );
      case LeadEditorTabType.Version:
        return (
          <div className="flex flex-1">
            <div className="flex-1"></div>
            <div className="flex-1"></div>
          </div>
        );
      case LeadEditorTabType.Events:
        var resourceComments = currentLead?.comments
          ? [...currentLead!.comments]
          : [];
        var resourceEvents = currentLead?.events
          ? [...currentLead!.events]
          : [];
        const combinedArray = [...resourceComments, ...resourceEvents];

        // Sort the combined array based on the createdAt property
        combinedArray.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        );
        return (
          <div className="px-4">
            {combinedArray.map((item, index) => {
              return isResourceComment(item) ? (
                <ResourceCommentRow
                  className="mb-2"
                  resourceComment={item}
                  onCommentSaveClick={(updatedComment: ResourceComment) => {
                    updatedComment.resourceId = currentLead!.id;

                    createComment(updatedComment).then(
                      (savedComment: ResourceComment) => {
                        savedComment.commenterUserProfile = getUserProfile(
                          savedComment.commenterProfileId,
                        );
                        var comments = currentLead!.comments
                          ? [...currentLead!.comments]
                          : [];
                        setCurrentLead({
                          ...currentLead!,
                          comments: [...comments, savedComment],
                        });
                      },
                    );
                  }}
                  onCommentRemoved={(commentToRemove: ResourceComment) => {
                    setCurrentLead({
                      ...currentLead!,
                      comments: [
                        ...currentLead!.comments.filter(
                          (c) => c.id !== commentToRemove.id,
                        ),
                      ],
                    });
                  }}
                />
              ) : (
                <ResourceEventRow
                  className={
                    selectedProfileVisitId != ""
                      ? item.diff &&
                        item.diff.profileVisitId === selectedProfileVisitId
                        ? "mb-2 opacity-100"
                        : "mb-2 opacity-30"
                      : "mb-2"
                  }
                  resourceEvent={item}
                  orgUserProfiles={orgUserProfiles}
                  onHoverProfileVisitEvent={(profileVisitId: string) =>
                    setSelectedProfileVisitId(profileVisitId)
                  }
                  onLeaveHoverProfileVisitEvent={() =>
                    setSelectedProfileVisitId("")
                  }
                />
              );
            })}
            <ResourceCommentRow
              className="mb-2"
              resourceComment={null}
              onCommentSaveClick={(updatedComment: ResourceComment) => {
                updatedComment.resourceId = currentLead!.id;

                createComment(updatedComment).then(
                  (savedComment: ResourceComment) => {
                    savedComment.commenterUserProfile = getUserProfile(
                      savedComment.commenterProfileId,
                    );
                    var comments = currentLead!.comments
                      ? [...currentLead!.comments]
                      : [];
                    setCurrentLead({
                      ...currentLead!,
                      comments: [...comments, savedComment],
                    });
                  },
                );
              }}
              onCommentRemoved={() => {}}
            />
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col py-4">
      <div className="flex flex-row justify-between">
        <CardTab
          icon={
            clientExist() ? (
              <AvatarImage photoId={currentClient?.logoId} />
            ) : (
              <User />
            )
          }
          text={clientExist() ? currentClient?.name! : "Add client"}
          secondaryText={clientExist() ? currentLead?.contactPersonName : ""}
          selected={tabTypeIsSelected(LeadEditorTabType.Client) && selected}
          onClick={() => handleTabClick(LeadEditorTabType.Client)}
          className="flex-1"
        />
        {currentLead ? (
          <CardTab
            icon={<Briefcase />}
            text={currentLead?.title != "" ? currentLead.title : "Assignment"}
            secondaryText=""
            selected={
              tabTypeIsSelected(LeadEditorTabType.Assignment) && selected
            }
            onClick={() => handleTabClick(LeadEditorTabType.Assignment)}
            className="flex-1"
          />
        ) : null}

        <CardTab
          icon={
            currentLead && getUserProfile(currentLead.profileId!) ? (
              <AvatarImage
                photoId={getUserProfile(currentLead.profileId!).profilePhotoId}
              />
            ) : (
              <User />
            )
          }
          text={
            currentLead
              ? getUserProfile(currentLead.profileId!)
                ? getUserProfile(currentLead.profileId!).profileData.name
                : "Member"
              : "Member"
          }
          selected={tabTypeIsSelected(LeadEditorTabType.Profile) && selected}
          onClick={() => handleTabClick(LeadEditorTabType.Profile)}
          className="flex-1"
        />
        <CardTab
          icon={
            currentLead && currentLead.profileLinkId ? (
              <div className="flex flex-1 items-center justify-center">
                <QRCode
                  size={30}
                  value={`https://voc.studio/p/${currentLead!.profileLinkId!}`}
                />
              </div>
            ) : (
              <FileText />
            )
          }
          text={
            currentLead && currentLead.profileLinkId
              ? "Profile version"
              : "Version"
          }
          selected={tabTypeIsSelected(LeadEditorTabType.Version) && selected}
          onClick={() => handleTabClick(LeadEditorTabType.Version)}
          className="flex-1"
        />

        {!isNew ? (
          <div className="flex flex-row">
            <CardTab
              text=""
              icon={
                <div className="relative">
                  <Bell />
                  {currentLead && currentLead.numberOfUnreadEvents > 0 ? (
                    <div className="w-4 h-4 bg-op-blue rounded-xl absolute bottom-4 left-[10px] flex justify-center items-center">
                      <h6 className="text-white text-sm font-bold select-none">
                        {currentLead.numberOfUnreadEvents}
                      </h6>
                    </div>
                  ) : null}
                </div>
              }
              selected={tabTypeIsSelected(LeadEditorTabType.Events) && selected}
              onClick={() => {
                if (currentLead) {
                  resetNotificationCount(currentLead.id);
                  setCurrentLead({
                    ...currentLead!,
                    numberOfUnreadEvents: 0,
                  });
                }
                handleTabClick(LeadEditorTabType.Events);
              }}
            />
            <PriorityMenu
              priority={currentLead?.priority ?? null}
              onChange={(newPriority: number) => {
                saveLead({ ...currentLead!, priority: newPriority });
              }}
            />
            {currentLead?.contractId !== "" ? null : (
              <RowMenu
                availableActions={[
                  RowMenuAction.ArchiveLead,
                  RowMenuAction.CreateContract,
                ]}
                onActionClick={(action: RowMenuAction) => {
                  switch (action) {
                    case RowMenuAction.ArchiveLead:
                      saveLead({ ...currentLead!, status: "archived" });
                      break;
                    case RowMenuAction.CreateContract:
                      if (onShowCreateContractDialog) {
                        onShowCreateContractDialog(currentLead!.id);
                      }
                      break;
                    default:
                      console.log("RowMenuAction not handled");
                  }
                }}
              />
            )}
          </div>
        ) : null}
      </div>
      <div>{selected ? renderContent() : null}</div>
    </div>
  );
};

export default LeadEditor;
