import {
  useMutation,
  useQueryClient,
  type UseMutationOptions,
} from "@tanstack/react-query";
import {
  DeleteV1OrganizationsByOrganizationIdLeadsByLeadIdData,
  DeleteV1OrganizationsByOrganizationIdLeadsByLeadIdResponse,
  DeleteV1OrganizationsByOrganizationIdLeadsByLeadIdError,
} from "../../../client";
import { deleteV1OrganizationsByOrganizationIdLeadsByLeadIdMutation } from "../../../client/@tanstack/react-query.gen";
import { organizationLeadArchivedOptions } from "./get-archived-leads";
import { getLeads } from "../../../requests";

export const useDeleteLead = (
  organizationId: string,
  options?: UseMutationOptions<
    DeleteV1OrganizationsByOrganizationIdLeadsByLeadIdResponse,
    DeleteV1OrganizationsByOrganizationIdLeadsByLeadIdError,
    DeleteV1OrganizationsByOrganizationIdLeadsByLeadIdData
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...deleteV1OrganizationsByOrganizationIdLeadsByLeadIdMutation(),
    onSuccess: (lead, variables, context) => {
      console.log("Mutation successful");

      console.log(
        "Query Key:",
        organizationLeadArchivedOptions(variables.path.organizationID).queryKey,
      );
      queryClient.setQueryData(
        organizationLeadArchivedOptions(variables.path.organizationID).queryKey,
        (oldData) => {
          console.log("Updating cache");
          if (!oldData) return [];
          return oldData.filter((item) => item.id !== variables.path.leadID);
        },
      );

      // Call the custom onSuccess handler if provided
      if (options?.onSuccess) {
        options?.onSuccess?.(lead, variables, context);
      }
    },
    onError: (error, variables, context) => {
      console.error("Mutation failed:", error);
      options?.onError?.(error, variables, context);
    },
    onSettled: (_, error, variables, context) => {
      console.log("Mutation settled");
      options?.onSettled?.(_, error, variables, context);
    },
    ...options, // Includes additional options without overwriting defaults
  });
};
