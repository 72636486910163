import { useEffect, useRef, useState } from "react";
import type { ClientWithContact, Lead } from "../../../../client";
import { cn } from "../../../../components/utils/cn";
import { useGetOrganization } from "../../../../services";
import { homeState } from "../../../../state/home";
import { useToggleActiveState } from "../utils/use-toggle-active-state";
import { LeadAssignmentButton } from "./LeadAssignmentButton";
import { LeadContractButton } from "./LeadContractButton";
import { LeadEventsButton } from "./LeadEventsButton";
import { LeadMemberButton } from "./LeadMemberButton";
import { LeadMenuButton } from "./LeadMenuButton";
import { LeadPanelContent } from "./LeadPanelContent";
import { LeadTemperatureButton } from "./LeadTemperatureButton";

interface Props {
  organizationId: string;
  client?: ClientWithContact;
  lead?: Lead;
  onUpdateLead?: (payload: Partial<Lead>) => void;
  onDeleteLead?: (leadId: string) => void;
  onDeleteContract?: () => void;
  onLeadCreated?: () => void;
  profileId?: string;
}

export const LeadRow = ({
  organizationId,
  client,
  lead,
  onUpdateLead,
  onDeleteLead,
  onDeleteContract,
  onLeadCreated,
  profileId,
}: Props) => {
  const { activeState, toggleActiveState } = useToggleActiveState({
    leadId: lead?.id ?? null,
  });

  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current && !lead?.id && activeState !== "assignment") {
      firstRender.current = false;
      toggleActiveState("assignment");
    }
  }, [lead?.id, toggleActiveState, activeState]);

  const [numberOfMatchingMembers, setNumberOfMatchingMembers] = useState<
    number | null
  >(null);

  const { data, error } = useGetOrganization(organizationId);

  const leadNumberOfUnreadEvents = lead?.numberOfUnreadEvents ?? 0;

  return (
    <li
      data-lead-id={lead?.id ?? "new"}
      className={cn(
        "flex flex-col p-2 rounded-2xl transition-colors duration-300 overflow-hidden",
        activeState && "lg:bg-gray-1",
      )}
    >
      <div
        className={cn(
          "hidden w-full lg:flex flex-row gap-4 items-center justify-between overflow-hidden md:overflow-auto",
          !lead && "lg:grid grid-cols-3",
        )}
      >
        <LeadAssignmentButton
          active={activeState === "assignment"}
          onClick={() => toggleActiveState("assignment")}
          title={lead?.title || lead?.assignmentInfo?.title}
          logoId={lead?.companyLogoId}
          companyName={lead?.companyName}
          disabled={lead?.status === "archived"}
        />

        <LeadMemberButton
          active={activeState === "member"}
          onClick={() => toggleActiveState("member")}
          profileId={lead?.profileId ?? undefined}
          organizationId={organizationId}
          profiles={data.profiles}
          numberOfMatchingMembers={
            !lead?.profileId && numberOfMatchingMembers != null
              ? numberOfMatchingMembers
              : null
          }
          disabled={lead?.status === "archived"}
        />

        {lead && lead.contractId && (
          <div className="flex flex-row gap-1 items-center self-stretch">
            <div className="flex flex-row gap-1 items-center self-stretch">
              <LeadContractButton
                validUntilDate={lead.contract ? lead.contract.endDate : ""}
                onClick={() => {
                  if (lead.id) homeState.contractCreatorLeadId = lead.id;
                }}
              />
            </div>

            <LeadMenuButton
              className="px-4"
              profileLinkId={lead?.profileLinkId}
              onArchive={() =>
                onUpdateLead?.({
                  status: lead.status === "archived" ? "" : "archived",
                })
              }
              archived={lead.status === "archived"}
              onDelete={() => {
                if (lead.id && onDeleteLead) {
                  onDeleteLead(lead.id);
                }
              }}
              onDeleteContract={onDeleteContract}
              leadId={lead.id}
              contractId={lead.contractId}
            />
          </div>
        )}
        {lead && lead.status !== "archived" && !lead.contractId && (
          <div className="flex flex-row gap-1 items-center self-stretch">
            <LeadEventsButton
              active={activeState === "events"}
              onClick={() => {
                toggleActiveState("events");
                // Reset the number of unread events to 0
                if (leadNumberOfUnreadEvents > 0)
                  onUpdateLead?.({ numberOfUnreadEvents: 0 });
              }}
              numberOfUnreadEvents={leadNumberOfUnreadEvents}
            />
            <LeadTemperatureButton
              priority={lead?.priority ?? 1}
              onPriorityChange={(priority) => onUpdateLead?.({ priority })}
              className="hidden lg:flex"
            />

            <LeadMenuButton
              className="px-4"
              profileLinkId={lead?.profileLinkId}
              onArchive={() =>
                onUpdateLead?.({
                  status: lead.status === "archived" ? "" : "archived",
                })
              }
              archived={lead.status === "archived"}
              onDelete={() => {
                if (lead.id && onDeleteLead) {
                  onDeleteLead(lead.id);
                }
              }}
              leadId={lead.id}
              contractId={lead.contractId}
            />
          </div>
        )}
        {lead && lead.status === "archived" && (
          <div className="flex flex-row gap-1 items-center self-stretch">
            <LeadMenuButton
              className="px-4"
              profileLinkId={lead?.profileLinkId}
              onArchive={() =>
                onUpdateLead?.({
                  status: lead.status === "archived" ? "" : "archived",
                })
              }
              archived={lead.status === "archived"}
              onDelete={() => {
                if (lead.id && onDeleteLead) {
                  onDeleteLead(lead.id);
                }
              }}
              contractId={lead.contractId}
            />
          </div>
        )}
      </div>

      <div className="flex flex-row justify-between lg:hidden">
        <LeadAssignmentButton
          active={activeState === "assignment"}
          onClick={() => toggleActiveState("assignment")}
          title={lead?.title || lead?.assignmentInfo?.title}
          logoId={lead?.companyLogoId}
          companyName={lead?.companyName}
          disabled={lead?.status === "archived"}
        />
        {lead?.status === "archived" && (
          <LeadMenuButton
            className="px-4"
            onArchive={() =>
              onUpdateLead?.({
                status: lead?.status === "archived" ? "" : "archived",
              })
            }
            archived={lead?.status === "archived"}
            id="lead-drawer-menu-menu"
            contractId={lead?.contractId}
            onDelete={() => {
              if (lead.id && onDeleteLead) {
                onDeleteLead(lead.id);
              }
            }}
          />
        )}
      </div>

      <LeadPanelContent
        state={activeState}
        organizationId={organizationId}
        className="hidden lg:block"
        divider
        leadId={lead?.id}
        onMatchingMembers={(numberOfMatchingMembers: number) => {
          setNumberOfMatchingMembers(numberOfMatchingMembers);
        }}
        onLeadCreated={() => {
          if (onLeadCreated) {
            onLeadCreated();
          }
        }}
        profileId={profileId}
      />
    </li>
  );
};

LeadRow.displayName = "LeadRow";
