import {
  queryOptions,
  useQuery,
  UseQueryOptions,
  useSuspenseQuery,
} from "@tanstack/react-query";
import type {
  GetV1OrganizationsByOrganizationIdMatchData,
  GetV1OrganizationsByOrganizationIdMatchError,
  GetV1OrganizationsByOrganizationIdMatchResponse,
  GetV1OrganizationsByOrganizationIdResponse,
} from "../../client";
import {
  getV1OrganizationsByOrganizationIdMatchOptions,
  getV1OrganizationsByOrganizationIdOptions,
} from "../../client/@tanstack/react-query.gen";
import { jsonParse } from "../utils/parse";
import { QueryConfig } from "../utils/query-config";

export const getMatchOptions = (organizationId: string, skills?: string[]) =>
  queryOptions({
    ...getV1OrganizationsByOrganizationIdMatchOptions({
      path: { organizationID: organizationId },
      query: skills ? { skills: skills } : undefined,
    }),
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

type GetMatchInput = {
  organizationId: string;
  skills?: string[];
};

export const useGetMatch = (
  { organizationId, skills }: GetMatchInput,
  config?: QueryConfig,
) => {
  return useQuery({ ...getMatchOptions(organizationId, skills), ...config });
};
