import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { getV1OrganizationsByOrganizationIdArchivedLeadsOptions } from "../../../client/@tanstack/react-query.gen";

export const organizationLeadArchivedOptions = (organizationId: string) => {
  return queryOptions({
    ...getV1OrganizationsByOrganizationIdArchivedLeadsOptions({
      path: { organizationID: organizationId },
    }),
  });
};

export const useGetOrganizationArchivedLeads = (organizationId: string) => {
  return useSuspenseQuery(organizationLeadArchivedOptions(organizationId));
};
