import { useParams } from "react-router-dom";
import { Button, Notice, Section, SectionTitle } from "../../../components";
import { useAnimate } from "../../../hooks";
import { useGetLeads } from "../../../services";
import { ExistingLeadRow } from "./components/ExistingLeadRow";
import { LeadRow } from "./components/LeadRow";
import { MobileMenuDrawer } from "./components/MobileMenuDrawer";
import { LinkCreatorDrawer } from "./components/link-creator/LinkCreatorDrawer";
import { Suspense, useState } from "react";
import { ArchivedLeads } from "./components/ArchivedLeads";
import { Loading } from "../layout/components/Loading";
import { ContractDrawer } from "./components/contracts/ContractDrawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";

const Component = () => {
  const { organizationId } = useParams();
  if (!organizationId) throw new Error("Organization ID is required");
  const [shouldFetch, setShouldFetch] = useState(false);
  const [addNewLeadSelected, setAddNewLeadSelected] = useState(false);

  const { data } = useGetLeads(organizationId);

  const [parent] = useAnimate();

  if (!data) return <p>No data</p>;

  return (
    <LinkCreatorDrawer>
      <ContractDrawer>
        <MobileMenuDrawer>
          <Section
            title="Leads"
            className="lg:overflow-x-scroll"
            containerComponent="ul"
            ref={parent}
          >
            {!addNewLeadSelected ? (
              <div
                className="rounded-4xl border border-gray-1 h-[72px] bg-white overflow-hidden group flex items-center justify-center hover:cursor-pointer"
                onClick={() => {
                  setAddNewLeadSelected(true);
                }}
              >
                <FontAwesomeIcon
                  width={10}
                  height={10}
                  size="sm"
                  icon={faCirclePlus}
                  className="text-red-3 w-8 h-8 group-hover:scale-150 transition-transform duration-200"
                />
              </div>
            ) : (
              <LeadRow
                organizationId={organizationId}
                onLeadCreated={() => setAddNewLeadSelected(false)}
              />
            )}

            {data
              .filter((l) => !l.contractId)
              .map((lead) => (
                <ExistingLeadRow
                  key={lead.id}
                  organizationId={organizationId}
                  lead={lead}
                />
              ))}

            <SectionTitle>Create new Lead & Profile Version</SectionTitle>
          </Section>

          <Notice
            className="bg-transparent"
            label="Contracts can be found under each members page"
          />

          {shouldFetch ? (
            <div>
              <div className="flex flex-1 w-full justify-center items-center">
                <Button onClick={() => setShouldFetch(false)}>
                  Hide archived leads
                </Button>
              </div>
              <div>
                <Suspense fallback={<Loading />}>
                  <ArchivedLeads organizationId={organizationId} />
                </Suspense>
              </div>
            </div>
          ) : (
            <div className="flex flex-1 w-full justify-center items-center pb-20">
              <Button onClick={() => setShouldFetch(true)}>
                Show archived leads
              </Button>
            </div>
          )}
        </MobileMenuDrawer>
      </ContractDrawer>
    </LinkCreatorDrawer>
  );
};

Component.displayName = "DashboardHome";

export default Component;
