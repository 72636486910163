import { Section } from "../../../../components";
import { useGetOrganizationArchivedLeads } from "../../../../services/organtization/lead/get-archived-leads";
import { ExistingLeadRow } from "./ExistingLeadRow";

interface Props {
  organizationId: string;
}

export const ArchivedLeads = ({ organizationId }: Props) => {
  const { data } = useGetOrganizationArchivedLeads(organizationId);

  return (
    <Section title="Archived leads" className="bg-gray-3">
      {data.map((lead) => (
        <ExistingLeadRow
          key={lead.id}
          organizationId={organizationId}
          lead={lead}
        />
      ))}
    </Section>
  );
};
