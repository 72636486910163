import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import type { ProfileLinkConfig, UserProfile } from "../../../../../client";
import { Avatar, Checkbox, Input } from "../../../../../components";
import { cn } from "../../../../../components/utils/cn";
import { getImageUrl } from "../../../../../utils/image";
import { ProjectSettingsContainer } from "./ProjectSettingsContainer";
import { useUpdateProfileLinkMotivation } from "../../../../../services/organtization/update-profile-link-motivation";
import { LeadRowCard } from "../card/LeadRowCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

interface Props {
  linkConfigData: ProfileLinkConfig;
  disabled: boolean;
  handleUpdateLink: (data: Partial<ProfileLinkConfig>) => void;
  profile?: UserProfile;
  organizationId: string;
  leadId: string;
  linkId: string;
  assignmentInfoAdded: boolean;
}

export const LinkCreatorPersonalBlock = ({
  linkConfigData,
  disabled,
  handleUpdateLink,
  profile,
  organizationId,
  leadId,
  linkId,
  assignmentInfoAdded,
}: Props) => {
  const [customPitch, setCustomPitch] = useState(
    linkConfigData?.customPitch ?? "",
  );
  const [assignmentPitch, setAssignmentPitch] = useState(
    linkConfigData?.assignmentPitch ?? "",
  );
  const [isGeneratingAssigmentPitch, setIsGeneratingAssigmentPitch] =
    useState(false);

  useEffect(() => {
    if (linkConfigData.assignmentPitch) {
      setAssignmentPitch(linkConfigData.assignmentPitch);
    }
  }, [linkConfigData]);

  const debounced = useDebouncedCallback<(val: string) => void>((value) => {
    handleUpdateLink({
      customPitch: value,
    });
  }, 500);

  const debouncedAssignmentPitch = useDebouncedCallback<(val: string) => void>(
    (value) => {
      handleUpdateLink({
        assignmentPitch: value,
      });
    },
    500,
  );

  const updateProfileAssignmentMotivation = useUpdateProfileLinkMotivation({
    onMutate: () => {
      setIsGeneratingAssigmentPitch(true);
    },
    onSuccess: (config) => {
      setIsGeneratingAssigmentPitch(false);
      if (config.assignmentPitch) {
        handleUpdateLink({ assignmentPitch: config.assignmentPitch });
        setAssignmentPitch(config.assignmentPitch);
      }
    },
    onSettled(data, error, variables, context) {
      setIsGeneratingAssigmentPitch(false);
    },
  });

  return (
    <div className="flex flex-col grow gap-6">
      <div className="flex flex-row items-center gap-4">
        <Checkbox
          checked={
            linkConfigData?.profileLinkPersonalInfoConfig?.showPersonalInfo
          }
          disabled={disabled}
          onCheckedChange={(checked) => {
            if (typeof checked !== "boolean") return;

            handleUpdateLink({
              profileLinkPersonalInfoConfig: {
                ...linkConfigData?.profileLinkPersonalInfoConfig,
                showPersonalInfo: checked,
              },
            });
          }}
        />

        <ProjectSettingsContainer
          className="flex flex-row items-center"
          enabled={
            linkConfigData?.profileLinkPersonalInfoConfig?.showPersonalInfo ??
            false
          }
        >
          <Avatar
            alt={
              typeof profile?.profileData.name === "string"
                ? profile.profileData.name
                : "Profile"
            }
            imageUrl={
              profile?.profilePhotoId
                ? getImageUrl(profile.profilePhotoId)
                : undefined
            }
            className={cn(
              "w-20 h-20 transition-all duration-300",
              linkConfigData?.profileLinkPersonalInfoConfig?.roundMode
                ? "rounded-[5rem]"
                : "",
              linkConfigData?.useProfilePicture
                ? "scale-100 opacity-100 mr-4"
                : "scale-0 opacity-0 w-0 mr-0",
            )}
          />

          <div className="flex flex-col">
            <p className="text-sm font-semibold">
              {typeof profile?.profileData.name === "string"
                ? profile.profileData.name
                : "Profile"}
            </p>
            {typeof profile?.profileData.title === "string" && (
              <p className="text-sm">{profile.profileData.title}</p>
            )}
          </div>
        </ProjectSettingsContainer>
      </div>

      <div className="flex flex-row items-start gap-4">
        <Checkbox
          checked={
            linkConfigData?.profileLinkPersonalInfoConfig?.showPitch ?? false
          }
          disabled={disabled}
          onCheckedChange={(checked) => {
            if (typeof checked !== "boolean") return;

            handleUpdateLink({
              profileLinkPersonalInfoConfig: {
                ...linkConfigData?.profileLinkPersonalInfoConfig,
                showPitch: checked,
              },
            });
          }}
        />
        <ProjectSettingsContainer
          className="flex flex-row items-center"
          enabled={
            linkConfigData?.profileLinkPersonalInfoConfig?.showPitch ?? false
          }
        >
          <p className="text-xs">
            {typeof profile?.profileData.pitch === "string"
              ? profile.profileData.pitch
              : ""}
          </p>
        </ProjectSettingsContainer>
      </div>

      <div className="flex flex-row items-start gap-4">
        <Checkbox
          checked={linkConfigData?.showCustomPitch ?? false}
          disabled={disabled}
          onCheckedChange={(checked) => {
            if (typeof checked !== "boolean") return;

            handleUpdateLink({
              showCustomPitch: checked,
            });
          }}
        />
        <ProjectSettingsContainer
          className="flex flex-row items-center grow"
          enabled={linkConfigData?.showCustomPitch ?? false}
        >
          <Input
            className="w-full bg-gray-1 focus-within:bg-gray-1"
            inputClassName="text-xs"
            placeholder="Add a custom pitch"
            value={customPitch}
            onChange={(e) => {
              const { value } = e.target;
              setCustomPitch(value);
              debounced(value);
            }}
          />
        </ProjectSettingsContainer>
      </div>
      <div className="flex flex-row items-start gap-4">
        <Checkbox
          checked={linkConfigData?.showAssignmentPitch ?? false}
          disabled={!assignmentInfoAdded}
          onCheckedChange={(checked) => {
            if (typeof checked !== "boolean") return;

            updateProfileAssignmentMotivation.mutate({
              path: {
                organizationID: organizationId,
                leadID: leadId,
                linkID: linkId,
              },
              body: {
                includeName: false,
              },
            });

            handleUpdateLink({
              showAssignmentPitch: checked,
            });
          }}
        />
        <ProjectSettingsContainer
          className="flex flex-row items-center grow"
          enabled={
            (linkConfigData?.showAssignmentPitch || !assignmentInfoAdded) ??
            false
          }
        >
          {assignmentInfoAdded ? (
            isGeneratingAssigmentPitch ? (
              <p className="text-sm mt-2">Generating assignment pitch...</p>
            ) : (
              <Input
                className="w-full bg-gray-1 focus-within:bg-gray-1"
                inputClassName="text-xs"
                placeholder="Add a custom motivation on why this profile is a good fit for this assignment"
                value={assignmentPitch}
                rows={5}
                onChange={(e) => {
                  const { value } = e.target;
                  setAssignmentPitch(value);
                  debouncedAssignmentPitch(value);
                }}
              />
            )
          ) : (
            <LeadRowCard
              className="text-xs bg-red-1 border-red-2 mt-3"
              type="dashed"
            >
              <div className="flex flex-1 items-center justify-between gap-3">
                <FontAwesomeIcon
                  width={14}
                  height={14}
                  icon={faInfoCircle}
                  className="w-4 h-4 shrink-0"
                />
                <p>
                  If you want to add a custom motivation on why this profile is
                  a good fit, please add assignment information first.
                </p>
              </div>
            </LeadRowCard>
          )}
        </ProjectSettingsContainer>
      </div>
    </div>
  );
};
