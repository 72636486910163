import {
  useMutation,
  useQueryClient,
  type UseMutationOptions,
} from "@tanstack/react-query";
import type { Options } from "@hey-api/client-fetch";

import {
  PutV1LeadsByLeadIdContractsByContractIdData,
  PutV1LeadsByLeadIdContractsByContractIdResponse,
  PutV1LeadsByLeadIdContractsByContractIdError,
  putV1LeadsByLeadIdContractsByContractId,
  Lead,
} from "../../../client";
import { organizationProfileLeadQueryOptions } from ".././lead/get-profile-leads";
import { jsonParse } from "../../utils/parse";
import { getLeadsQueryKey } from "../lead/utils";

const mutationKey = ["updateContract"];

export const useUpdateContract = (
  options: UseMutationOptions<
    PutV1LeadsByLeadIdContractsByContractIdResponse,
    PutV1LeadsByLeadIdContractsByContractIdError,
    PutV1LeadsByLeadIdContractsByContractIdData
  > = {},
  organizationId: string,
  profileId?: string | null,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey,
    mutationFn: async (data: PutV1LeadsByLeadIdContractsByContractIdData) => {
      const { data: responseData } =
        await putV1LeadsByLeadIdContractsByContractId({
          ...data,
          throwOnError: true,
        });
      return responseData;
    },
    ...options,
    onSuccess: (lead, variables, context) => {
      const newLead = jsonParse<Lead>(lead);

      queryClient.setQueryData(getLeadsQueryKey(newLead.orgId!), (oldData) => {
        if (!oldData) return [newLead];
        const index = oldData.findIndex((item) => item.id === newLead.id);
        if (index === -1) return [...oldData, newLead];
        return [
          ...oldData.slice(0, index),
          newLead,
          ...oldData.slice(index + 1),
        ];
      });

      if (profileId) {
        const profileQueryKey = organizationProfileLeadQueryOptions(
          organizationId,
          profileId,
        ).queryKey;

        queryClient.setQueryData(profileQueryKey, (oldData) => {
          if (!oldData) return [newLead];
          const index = oldData.findIndex((item) => item.id === newLead.id);
          if (index === -1) return [...oldData, newLead];
          return [
            ...oldData.slice(0, index),
            newLead,
            ...oldData.slice(index + 1),
          ];
        });
      }

      // Call the custom onSuccess handler if provided
      options?.onSuccess?.(lead, variables, context);
    },
    onSettled: (data, error, variables, context) => {
      // Invalidate the query to ensure fresh data

      // Call the custom onSettled handler if provided
      options?.onSettled?.(data, error, variables, context);
    },
  });
};
