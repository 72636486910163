import { useRef, type PropsWithChildren } from "react";
import type { ProfileLinkConfig } from "../../../../../client";
import { Button, Switch } from "../../../../../components";
import { BulletSectionTitle } from "../../../../../components/section/BulletSectionTitle";
import { cn } from "../../../../../components/utils/cn";
import { useUpdateProfileLinkMotivation } from "../../../../../services/organtization/update-profile-link-motivation";
import { toast } from "sonner";

interface Props {
  disabled?: boolean;
  linkConfig?: ProfileLinkConfig;
  onUpdate: (payload: Partial<ProfileLinkConfig>) => void;
  organizationId: string;
  leadId: string;
  linkId: string;
}

export const LinkCreatorSideMenu = ({
  disabled,
  linkConfig,
  onUpdate,
  organizationId,
  leadId,
  linkId,
}: Props) => {
  const toastId = useRef<string | number>();

  const updateProfileAssignmentMotivation = useUpdateProfileLinkMotivation({
    onMutate: () => {
      toastId.current = toast.loading("Generating new assignment pitch...");
    },
    onSuccess: (config) => {
      toast.success("Done!", { id: toastId.current });
      if (config.assignmentPitch) {
        onUpdate({ assignmentPitch: config.assignmentPitch });
      }
    },
    onSettled: () => {
      toast.success("Done!", { id: toastId.current });
    },
  });

  const updateAssignmentPitch = (includeName: boolean) => {
    updateProfileAssignmentMotivation.mutate({
      path: {
        organizationID: organizationId,
        leadID: leadId,
        linkID: linkId,
      },
      body: {
        includeName,
      },
    });
  };

  return (
    <div className="bg-gray-1 border border-gray-3 border-dashed rounded-3xl grow-0 shrink-0 xl:sticky xl:top-0 min-w-72">
      <div className="p-4 flex flex-col gap-2">
        <BulletSectionTitle>General</BulletSectionTitle>
        <SettingRow>
          <Switch
            checked={linkConfig?.useProfilePicture}
            onCheckedChange={(checked) => {
              if (typeof checked !== "boolean") return;

              onUpdate({
                useProfilePicture: checked,
              });
            }}
            disabled={disabled}
          />
          <SettingText>Display picture</SettingText>
        </SettingRow>
        <SettingRow>
          <Switch
            checked={linkConfig?.profileLinkPersonalInfoConfig?.roundMode}
            onCheckedChange={(checked) => {
              if (typeof checked !== "boolean") return;

              onUpdate({
                profileLinkPersonalInfoConfig: {
                  ...linkConfig?.profileLinkPersonalInfoConfig,
                  roundMode: checked,
                },
              });
            }}
            disabled={disabled || !linkConfig?.useProfilePicture}
          />
          <SettingText>Round picture</SettingText>
        </SettingRow>
        <SettingTitle>Assignment pitch</SettingTitle>
        <SettingRow>
          <Switch
            checked={linkConfig?.includeNameInAssignmentPitch}
            disabled={!linkConfig?.showAssignmentPitch}
            onCheckedChange={(checked) => {
              if (typeof checked !== "boolean") return;

              updateAssignmentPitch(checked);

              onUpdate({
                includeNameInAssignmentPitch: checked,
              });
            }}
          />
          <SettingText>Include name</SettingText>
        </SettingRow>
        <SettingRow>
          <Button
            variant="primary"
            onClick={() => {
              updateAssignmentPitch(
                linkConfig?.includeNameInAssignmentPitch ?? false,
              );
            }}
            disabled={!linkConfig?.showAssignmentPitch}
          >
            Generate new
          </Button>
        </SettingRow>
        <SettingTitle>Work experience</SettingTitle>
        <SettingRow>
          <Switch
            checked={linkConfig?.workplaceLinkConfig?.displaySkills}
            disabled={disabled}
            onCheckedChange={(checked) => {
              if (typeof checked !== "boolean") return;

              onUpdate({
                workplaceLinkConfig: {
                  ...linkConfig?.workplaceLinkConfig,
                  displaySkills: checked,
                },
              });
            }}
          />
          <SettingText>Skills</SettingText>
        </SettingRow>
        <SettingRow>
          <Switch
            checked={linkConfig?.workplaceLinkConfig?.displayDescription}
            disabled={disabled}
            onCheckedChange={(checked) => {
              if (typeof checked !== "boolean") return;

              onUpdate({
                workplaceLinkConfig: {
                  ...linkConfig?.workplaceLinkConfig,
                  displayDescription: checked,
                },
              });
            }}
          />
          <SettingText>Descriptions</SettingText>
        </SettingRow>
        {/* TODO: Add connected projects */}
        {/* <SettingRow>
        <Switch
          checked={linkConfig?.workplaceLinkConfig?.displayConnectedProjects}
          disabled={disabled}
          onCheckedChange={(checked) => {
            if (typeof checked !== "boolean") return;

            onUpdate({
              workplaceLinkConfig: {
                ...linkConfig?.workplaceLinkConfig,
                displayConnectedProjects: checked,
              },
            });
          }}
        />
        <SettingText>Connected projects</SettingText>
      </SettingRow> */}
        <SettingTitle>Projects</SettingTitle>
        <SettingRow>
          <Switch
            checked={linkConfig?.projectsLinkConfig?.displaySkills}
            disabled={disabled}
            onCheckedChange={(checked) => {
              if (typeof checked !== "boolean") return;

              onUpdate({
                projectsLinkConfig: {
                  ...linkConfig?.projectsLinkConfig,
                  displaySkills: checked,
                },
              });
            }}
          />
          <SettingText>Skills</SettingText>
        </SettingRow>
      </div>
    </div>
  );
};

const SettingRow = ({
  children,
  position = "start",
}: PropsWithChildren<{ position?: "start" | "end" }>) => (
  <div
    className={cn(
      "flex flex-row gap-2 items-center",
      position === "start" ? "justify-start" : "justify-end",
    )}
  >
    {children}
  </div>
);

const SettingText = ({ children }: PropsWithChildren) => (
  <p className="text-sm">{children}</p>
);

const SettingTitle = ({ children }: PropsWithChildren) => (
  <BulletSectionTitle className="pt-4">{children}</BulletSectionTitle>
);
