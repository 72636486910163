import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  type ChangeEventHandler,
  type ComponentProps,
  type FocusEventHandler,
  type InputHTMLAttributes,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Button } from "../buttons";
import { cn } from "../utils/cn";
import type { TransformFunction, ValidationFunction } from "./types";
import { DEFAULT_TYPING_TIMEOUT, isString } from "./utils";

interface ButtonProps {
  onClick: (value: string) => void;
  label: string;
  type?: ComponentProps<typeof Button>["type"];
}

interface Props
  extends InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  leftIcon?: IconProp;
  rightButton?: ButtonProps;
  label?: string;
  rows?: number;
  onFinishWriting?: (value: string) => void;
  timeout?: number;
  transformValue?: TransformFunction;
  validate?: ValidationFunction;
  inputClassName?: string;
  onFocus?: () => void;
}

export interface InputRef {
  clear: () => void;
  value: string;
}

type HTMLGeneralInput = HTMLInputElement | HTMLTextAreaElement;

export const Input = forwardRef<InputRef, Props>(
  (
    {
      label,
      leftIcon,
      className,
      rows = 1,
      onFinishWriting,
      value: initialValue,
      onBlur,
      onChange,
      disabled,
      timeout = DEFAULT_TYPING_TIMEOUT,
      transformValue,
      validate = () => true,
      rightButton,
      onFocus,
      inputClassName,
      ...props
    },
    ref,
  ) => {
    const InputComponent = rows > 1 ? "textarea" : "input";

    if (initialValue != null && !isString(initialValue))
      throw new Error("value must be a string");

    const value = useRef(initialValue ?? "");
    const [valueState, setValueState] = useState(value.current);
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(
      null,
    );
    const [isValid, setIsValid] = useState(validate(value.current));

    const didChange = useRef(false);

    useImperativeHandle(ref, () => ({
      clear: () => {
        value.current = "";
        setValueState("");
        setIsValid(validate(value.current));
      },
      value: value.current,
    }));

    useEffect(() => {
      if (initialValue != null && initialValue !== value.current) {
        value.current = initialValue;
        setValueState(initialValue);
        setIsValid(validate(initialValue));
      }
    }, [validate, initialValue]);

    const handleFinishWriting = useCallback(
      (value: string) => {
        const newValue = transformValue?.(value) ?? value;

        if (
          validate(newValue) &&
          initialValue !== newValue &&
          didChange.current
        )
          onFinishWriting?.(newValue);
        didChange.current = false;
      },
      [initialValue, onFinishWriting, transformValue, validate],
    );

    const handleBlur: FocusEventHandler<HTMLGeneralInput> = useCallback(
      (event) => {
        event.stopPropagation(); // Stop the event from bubbling up to parent elements
        handleFinishWriting(value.current);
        onBlur?.(event);
      },
      [handleFinishWriting, onBlur],
    );

    const handleFocus: FocusEventHandler<HTMLGeneralInput> = useCallback(
      (event) => {
        if (onFocus) {
          onFocus();
        }
      },
      [],
    );

    const handleChange: ChangeEventHandler<HTMLGeneralInput> = useCallback(
      (event) => {
        const newValue = event.target.value;
        value.current = newValue;
        setValueState(newValue);
        didChange.current = true;

        if (typingTimeout) clearTimeout(typingTimeout);
        const newTimeout = setTimeout(
          () => handleFinishWriting(newValue),
          timeout,
        );
        setTypingTimeout(newTimeout);
        setIsValid(validate(newValue));

        onChange?.(event);
      },
      [onChange, handleFinishWriting, typingTimeout, timeout, validate],
    );

    useEffect(() => {
      return () => {
        if (typingTimeout) clearTimeout(typingTimeout);
      };
    }, [typingTimeout]);

    return (
      <div
        className={cn(
          "group flex items-start border border-gray-4 px-4 gap-3 rounded-2xl transition-colors hover:bg-gray-3 focus-within:bg-gray-4 hover:focus-within:bg-gray-4",
          !isValid &&
            "border-red-3 bg-red-1 hover:bg-red-1 focus-within:bg-red-2 hover:focus-within:bg-red-2",
          className,
        )}
      >
        {leftIcon && (
          <FontAwesomeIcon
            icon={leftIcon}
            className="text-black mt-4"
            width={14}
            height={14}
          />
        )}
        <InputComponent
          className={cn(
            "py-4 text-sm bg-transparent outline-none flex-grow text-black placeholder:text-black resize-none",
            inputClassName,
          )}
          placeholder={label}
          rows={rows}
          disabled={disabled}
          {...props}
          value={valueState}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
        />
        {rightButton && (
          <Button
            disabled={disabled || !isValid}
            className="my-2"
            type={rightButton.type ?? "button"}
            onClick={() =>
              rightButton.onClick(
                transformValue?.(value.current) ?? value.current,
              )
            }
          >
            {rightButton.label}
          </Button>
        )}
      </div>
    );
  },
);

Input.displayName = "Input";
