import type { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faFilePdf, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import {
  faBuildingCircleCheck,
  faBuildingCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PopoverMenu } from "../../../../../components";
import { cn } from "../../../../../components/utils/cn";
import { homeState } from "../../../../../state/home";

interface Props {
  onArchive: () => void;
  onDelete?: () => void;
  onDeleteContract?: () => void;
  leadId?: string;
  profileLinkId?: string;
  archived?: boolean;
  contractId: string | undefined;
}

export const LeadMenu = ({
  onArchive,
  onDelete,
  onDeleteContract,
  leadId,
  profileLinkId,
  archived,
  contractId,
}: Props) => {
  if (archived === true && onDelete) {
    return (
      <PopoverMenu>
        <LeadMenuRow
          icon={faBuildingCircleCheck}
          text="Reopen lead"
          onClick={onArchive}
        />
        <LeadMenuRow icon={faTrashCan} text="Delete" onClick={onDelete} />
      </PopoverMenu>
    );
  }

  return (
    <PopoverMenu>
      {profileLinkId ? (
        <LeadMenuRow
          icon={faFilePdf}
          text="Download as PDF"
          onClick={() =>
            window.open(
              window.location.origin + `/p/${profileLinkId}?pdf=true`,
              "_blank",
            )
          }
        />
      ) : null}
      <LeadMenuRow
        icon={faBuildingCircleCheck}
        text={contractId ? "Delete contract" : "Turn into contract"}
        onClick={() => {
          if (contractId && onDeleteContract) {
            onDeleteContract();
            return;
          }
          if (leadId) {
            homeState.contractCreatorLeadId = leadId;
          }
        }}
      />
      {!contractId ? (
        <LeadMenuRow
          icon={faBuildingCircleXmark}
          text="Archive lead"
          onClick={onArchive}
        />
      ) : null}
    </PopoverMenu>
  );
};

interface LeadMenuRowProps {
  onClick: () => void;
  icon: IconDefinition;
  text: string;
  iconClassName?: string;
}

const LeadMenuRow = ({
  icon,
  text,
  onClick,
  iconClassName,
}: LeadMenuRowProps) => (
  <PopoverMenu.Item onClick={onClick}>
    <FontAwesomeIcon
      width={14}
      height={14}
      icon={icon}
      className={cn("shrink-0", iconClassName)}
    />
    <span className="text-sm text-black">{text}</span>
  </PopoverMenu.Item>
);

LeadMenu.displayName = "LeadMenu";
