import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar } from "../../../../components";
import { cn } from "../../../../components/utils/cn";
import { useGetProfile } from "../../../../services";
import { getImageUrl } from "../../../../utils/image";
import { LeadButton, type LeadButtonProps } from "./LeadButton";
import { UserProfile } from "../../../../client";
import vocsLogo from "../../../../assets/vocs-logo.png";
import { faUser } from "@fortawesome/free-solid-svg-icons";

interface Props extends LeadButtonProps {
  profileId?: string;
  profiles: UserProfile[];
  organizationId: string;
  numberOfMatchingMembers?: number | null;
}

export const LeadMemberButton = ({
  profileId,
  profiles,
  organizationId,
  className,
  numberOfMatchingMembers,
  ...props
}: Props) => {
  const { data } = useGetProfile(
    {
      organizationId,
      profileId: profileId ?? "",
    },
    {
      enabled: !!profileId,
    },
  );

  const profileName = () => {
    if (profileId) {
      let profileData = profiles.filter((p) => p.profileId === profileId)[0]
        .profileData;
      return typeof profileData.name === "string" ? profileData.name : "member";
    }

    return "Member";
  };

  const profileImage = () => {
    if (profileId) {
      let profileData = profiles.filter((p) => p.profileId === profileId)[0];
      return (
        <Avatar
          // TODO: Default image
          imageUrl={
            profileData.profilePhotoId
              ? getImageUrl(profileData.profilePhotoId)
              : undefined
          }
          alt={
            typeof profileData.profileData.name === "string"
              ? profileData.profileData.name
              : "Profile"
          }
          className="w-10 h-10 shrink-0"
        />
      );
    }
    return <FontAwesomeIcon icon={faUser} className="w-5 h-5" />;
  };

  return (
    <LeadButton
      {...props}
      className={cn("w-56 break-all overflow-hidden flex", className)}
    >
      <div className="flex flex-1 items-center gap-2">
        {profileImage()}
        <div className="flex flex-col">
          <p className="truncate w-full">{profileName()}</p>
          {numberOfMatchingMembers ? (
            <p className="text-sm text-red-3 font-semibold">
              {`${numberOfMatchingMembers} matching members`}
            </p>
          ) : null}
        </div>
      </div>
    </LeadButton>
  );
};

LeadMemberButton.displayName = "LeadMemberButton";
