import type { OrganizationProfile } from "../../../../../client";
import { Button, ImageButton } from "../../../../../components";
import { useUpdateLinkStatus } from "../../../../../services";

import { getImageUrl } from "../../../../../utils/image";
interface Props {
  profile?: OrganizationProfile;
  handleClose?: () => void;
  handleCreateClick: () => void;
  contractInputIValid: boolean;
  contractExists: boolean;
}

export const ContractHeaderHeader = ({
  profile,
  handleClose,
  handleCreateClick,
  contractInputIValid,
  contractExists,
}: Props) => {
  const status = useUpdateLinkStatus();

  const isPending = status === "pending";

  return (
    <div className="flex flex-row justify-between items-center gap-4 px-4 sm:px-5 py-4 bg-gray-1 border-b border-gray-2 rounded-tl-2xl">
      <div className="flex flex-row sm:px-4 items-center justify-center gap-2">
        <p className="text-sm hidden sm:block">Turn lead into contract</p>
      </div>
      <div>
        <Button variant="secondary" onClick={handleClose} disabled={isPending}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleCreateClick}
          disabled={isPending || !contractInputIValid}
        >
          {contractExists ? "Update contract" : "Create contract"}
        </Button>
      </div>
    </div>
  );
};
